const endpoint = `http://localhost:4444`;
const WSendpoint = `ws://localhost:4444`;
const prodEndpoint = "https://api.starthawk.io/";
const WSprodEndpoint = "wss://api.starthawk.io/";
const perPage = 9;
const perPagePremium = 6;
const perPageCommunityQuestions = 10;
const perPageAddedUsers = 8;
const perPageApps = 12;
const perPageBlogPosts = 12;
const amountOfAllowedMessages = 10;
const sentryDsn = "https://d9c25e67585142ce9c0fa82ed407f535@sentry.io/1728810";
const stripePubKeyDev = "pk_test_51NZvraFRMAbk4g6pMuLUhnziVDzJLulRtGsFUJJRTStZHS5f8cI3Xt7bVJT5TVgPP8z7GznygfHF6mmk78IQsJvX00JPeJlw5S";
const stripePubKeyProd = "pk_live_51NZvraFRMAbk4g6pd2RIachEWKwzJm6X9q9X5bRD57cBTYeDLNbqgWUhNV76BIg7an6viRnowmMbB6mtbXCGtwtQ00i0NpHAcf";

const snackbarOptions = {
  style: {
    backgroundColor: "#51c686",
    border: "1px solid #51c686",
    borderRadius: "50px",
    fontWeight: "bold",
    color: "white",
    fontFamily: "OpenSans-Regular",
    fontSize: "14px",
    textAlign: "center",
    boxShadow: "0 0 3px rgba(73, 89, 106, 0.3)",
  },
  closeStyle: {
    color: "white",
  },
};

module.exports = {
  endpoint,
  WSendpoint,
  prodEndpoint,
  WSprodEndpoint,
  perPage,
  perPagePremium,
  perPageCommunityQuestions,
  amountOfAllowedMessages,
  sentryDsn,
  perPageAddedUsers,
  perPageApps,
  perPageBlogPosts,
  stripePubKeyDev,
  stripePubKeyProd,
  snackbarOptions,
};
