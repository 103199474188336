import gql from 'graphql-tag';

const LOCAL_TOGGLE_OVERLAY_MUTATION = gql`
  mutation {
    toggleOverlay @client
  }
`;

const LOCAL_TOGGLE_FOUNDER_FILTERS = gql`
  mutation {
    toggleFounderFilters @client
  }
`;

const LOCAL_TOGGLE_UPLOAD_PROFILE_PIC = gql`
  mutation {
    toggleUploadProfilePic @client
  }
`;

const LOCAL_TOGGLE_APP_PROMO_CODE = gql`
  mutation(
    $appId: String
  ) {
    toggleAppPromoCode(appId: $appId) @client
  }
`;

const LOCAL_TOGGLE_FOUNDERS_GRID_ADDITIONAL_DETAILS_MUTATION = gql`
  mutation(
    $foundersGridAdditionalDetailsId: String
  ) {
    toggleFoundersGridAdditionalDetails(
      foundersGridAdditionalDetailsId: $foundersGridAdditionalDetailsId
    ) @client
  }
`;

const LOCAL_TOGGLE_PREMIUM_COFOUNDER = gql`
  mutation(
    $message: String
  ) {
    togglePremiumCofounder(
      message: $message
    ) @client
  }
`;

const LOCAL_TOGGLE_MESSAGES = gql`
  mutation(
    $convoOtherUserId: String
    $convoOtherUserProfilePic: String
    $convoOtherUserFirstName: String
    $activeConvoId: ID
  ) {
    toggleMessages(
      convoOtherUserId: $convoOtherUserId
      convoOtherUserProfilePic: $convoOtherUserProfilePic
      convoOtherUserFirstName: $convoOtherUserFirstName   
      activeConvoId: $activeConvoId  
    ) @client
  }
`;

const LOCAL_TOGGLE_MOBILE_MENU = gql`
  mutation(
    $value: Boolean
  ) {
    toggleMobileMenu(
      value: $value
    ) @client
  }
`;

export {
  LOCAL_TOGGLE_OVERLAY_MUTATION,
  LOCAL_TOGGLE_MESSAGES,
  LOCAL_TOGGLE_MOBILE_MENU,
  LOCAL_TOGGLE_PREMIUM_COFOUNDER,
  LOCAL_TOGGLE_FOUNDERS_GRID_ADDITIONAL_DETAILS_MUTATION,
  LOCAL_TOGGLE_FOUNDER_FILTERS,
  LOCAL_TOGGLE_UPLOAD_PROFILE_PIC,
  LOCAL_TOGGLE_APP_PROMO_CODE
}
