import React, { PureComponent } from "react";
import classNames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Link from "next/link";

import Cta from "../cta/cta";

import convertProfilePicUrl from "../../utils/convert-profile-pic-url";

import "./profile-pic.scss";

class ProfilePic extends PureComponent {
  render() {
    const {
      profilePic,
      visibleByDefault,
      width,
      height,
      activityStatus,
      activityStatusTooltipBelow,
      firstName,
      href,
      hrefAs,
    } = this.props;

    return (
      <div
        className="profile-pic"
        style={{ width: `${width}px`, height: `${height}px` }}
      >
        <div className="profile-pic__inner">
          {href ? (
            <Link href={href} as={hrefAs}>
              <a>
                <LazyLoadImage
                  alt={"user"}
                  title={firstName}
                  height={height}
                  src={convertProfilePicUrl(profilePic, height, width)}
                  effect="opacity"
                  width={width}
                  visibleByDefault={visibleByDefault}
                  placeholderSrc={
                    "https://res.cloudinary.com/djrpmnbbi/image/upload/v1581093819/website-assets/deleted-user/profile-pic-med.jpg"
                  }
                />
              </a>
            </Link>
          ) : (
            <LazyLoadImage
              alt={"user"}
              title={firstName}
              height={height}
              src={convertProfilePicUrl(profilePic, height, width)}
              effect="opacity"
              width={width}
              visibleByDefault={visibleByDefault}
              placeholderSrc={
                "https://res.cloudinary.com/djrpmnbbi/image/upload/v1581093819/website-assets/deleted-user/profile-pic-med.jpg"
              }
            />
          )}
        </div>
        {activityStatus && (
          <div
            className={classNames("profile-pic__activity-status", {
              "profile-pic__activity-status--very":
                activityStatus === "Very active",
              "profile-pic__activity-status--fairly":
                activityStatus === "Fairly active",
              "profile-pic__activity-status--not-very":
                activityStatus === "Not very active",
              "profile-pic__activity-status--popup-below": activityStatusTooltipBelow,
            })}
          >
            <div className="profile-pic__activity-status__tool-tip">
              <div className="pattern" />
              <span>{firstName}'s profile is </span>
              <br />
              <strong
                className={classNames(
                  "profile-pic__activity-status__colored-text",
                  {
                    "profile-pic__activity-status__colored-text--very":
                      activityStatus === "Very active",
                    "profile-pic__activity-status__colored-text--fairly":
                      activityStatus === "Fairly active",
                    "profile-pic__activity-status__colored-text--not-very":
                      activityStatus === "Not very active",
                  }
                )}
              >
                {activityStatus}
              </strong>
              <br />
              <span>
                {` A user's activity is measured by several metrics such as how recently they have logged on and how often they read their messages`}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ProfilePic.propTypes = {};

ProfilePic.defaultProps = {
  visibleByDefault: false,
  width: 60,
  height: 60,
};

export default ProfilePic;
